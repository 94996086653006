// Generated by Framer (575e68f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {KcoUKfV50: {hover: true}, LctqgqpQQ: {hover: true}};

const cycleOrder = ["KcoUKfV50", "LctqgqpQQ"];

const serializationHash = "framer-CWbw6"

const variantClassNames = {KcoUKfV50: "framer-v-oz51tl", LctqgqpQQ: "framer-v-7r9jqe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "KcoUKfV50", "Variant 2": "LctqgqpQQ"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, dOdcO5zjQ: click ?? props.dOdcO5zjQ, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "KcoUKfV50"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dOdcO5zjQ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "KcoUKfV50", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapnzxik7 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (dOdcO5zjQ) {const res = await dOdcO5zjQ(...args);
if (res === false) return false;}
setVariant("LctqgqpQQ")
})

const onTapnytp9x = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (dOdcO5zjQ) {const res = await dOdcO5zjQ(...args);
if (res === false) return false;}
setVariant("KcoUKfV50")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-oz51tl", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"KcoUKfV50"} onTap={onTapnzxik7} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"KcoUKfV50-hover": {"data-framer-name": undefined}, "LctqgqpQQ-hover": {"data-framer-name": undefined}, LctqgqpQQ: {"data-framer-name": "Variant 2", onTap: onTapnytp9x}}, baseVariant, gestureVariant)}><motion.div className={"framer-zhwdf9"} layoutDependency={layoutDependency} layoutId={"OZKiR0w2T"}><ComponentViewportProvider ><motion.div className={"framer-14xb8gj-container"} layoutDependency={layoutDependency} layoutId={"e1gk5vwI7-container"} style={{rotate: 0}} variants={{LctqgqpQQ: {rotate: 180}}}><Phosphor color={"var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(23, 23, 23))"} height={"100%"} iconSearch={"House"} iconSelection={"CaretDown"} id={"e1gk5vwI7"} layoutId={"e1gk5vwI7"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"} {...addPropertyOverrides({"KcoUKfV50-hover": {color: "var(--token-9065ca1f-1dfb-4011-af90-7c0570697ffc, rgb(111, 68, 255))"}, "LctqgqpQQ-hover": {color: "var(--token-9065ca1f-1dfb-4011-af90-7c0570697ffc, rgb(111, 68, 255))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CWbw6.framer-1c0fq0t, .framer-CWbw6 .framer-1c0fq0t { display: block; }", ".framer-CWbw6.framer-oz51tl { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 6px 10px 6px 10px; position: relative; width: min-content; }", ".framer-CWbw6 .framer-zhwdf9 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-CWbw6 .framer-14xb8gj-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CWbw6.framer-oz51tl, .framer-CWbw6 .framer-zhwdf9 { gap: 0px; } .framer-CWbw6.framer-oz51tl > *, .framer-CWbw6 .framer-zhwdf9 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-CWbw6.framer-oz51tl > :first-child, .framer-CWbw6 .framer-zhwdf9 > :first-child { margin-left: 0px; } .framer-CWbw6.framer-oz51tl > :last-child, .framer-CWbw6 .framer-zhwdf9 > :last-child { margin-right: 0px; } }", ".framer-CWbw6.framer-v-oz51tl.hover.framer-oz51tl { padding: 8px 10px 4px 10px; }", ".framer-CWbw6.framer-v-7r9jqe.hover.framer-oz51tl { padding: 4px 10px 6px 10px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"LctqgqpQQ":{"layout":["auto","auto"]},"a42bc6dg5":{"layout":["auto","auto"]},"ihl9A6mT6":{"layout":["auto","auto"]}}}
 * @framerVariables {"dOdcO5zjQ":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWUeEGY4eh: React.ComponentType<Props> = withCSS(Component, css, "framer-CWbw6") as typeof Component;
export default FramerWUeEGY4eh;

FramerWUeEGY4eh.displayName = "Button/Dropdown Arrow";

FramerWUeEGY4eh.defaultProps = {height: 32, width: 40};

addPropertyControls(FramerWUeEGY4eh, {variant: {options: ["KcoUKfV50", "LctqgqpQQ"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, dOdcO5zjQ: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerWUeEGY4eh, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})